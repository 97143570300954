// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  buildingData: [],
  roomData: [],
  studentData: [],
  staffData: [],
  examDateAndTimeData: [],
  examDateData: [],
  subjectData: [],
  institutionData: [],
  dataSummary: [],
  defaultStartTime: "",
  defaultEndTime: "",
  ganttGroup: [],
  ganttItem: [],
  localLoader: 0,
  preferredRoom: 0,
  editPreferredRoomModal: 0,
  preferredRoomsSaved: 0,
  algorithmStatus: "NOT_CREATED",
  algorithmErrors: "",
  resultStudentData: [],
  updateSubjectIds: [],
  clearErrorConfirmationModal: 0,
  errorsListModal: 0,
  showClearModal: 0,
  clearType: "",
  searchStudentId: 0,
  accessLevelData: 0,
  editedSubjectRoomPreferrenceData: [],
  newStudentId: 0,
  selectedSeatingPreference: "",
  generateResultLoader: false,
  dataImportErrorList: [],
  examDateAndTimeIdData: [],
  examSubjectSummaryIdData: [],
  resultRoomByExamDateAndTimeIdData: [],
  resultSubjectByExamDateAndTimeIdData: [],
  resultStaffData: [],
  staffDataImportErrorList: [],
  staffSchedulingAlgorithmStatus: null,
  selectedStaffSchedulePreference: "",
  seatingOrderData: [],
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
  name: "menu",
  initialState,
  reducers: {
    BuildingData(state, action) {
      state.buildingData = action.payload;
    },

    RoomData(state, action) {
      state.roomData = action.payload;
    },

    StudentData(state, action) {
      state.studentData = action.payload;
    },

    StaffData(state, action) {
      state.staffData = action.payload;
    },

    ExamDateAndTimeData(state, action) {
      state.examDateAndTimeData = action.payload;
    },

    ExamDateData(state, action) {
      state.examDateData = action.payload;
    },

    SubjectData(state, action) {
      state.subjectData = action.payload;
    },

    InstitutionData(state, action) {
      state.institutionData = action.payload;
    },

    DataSummary(state, action) {
      state.dataSummary = action.payload;
    },

    DefaultStartTime(state, action) {
      state.defaultStartTime = action.payload;
    },

    DefaultEndTime(state, action) {
      state.defaultEndTime = action.payload;
    },

    GanttGroup(state, action) {
      state.ganttGroup = action.payload;
    },

    GanttItem(state, action) {
      state.ganttItem = action.payload;
    },

    LocalLoader(state, action) {
      state.localLoader = action.payload;
    },

    PreferredRoom(state, action) {
      state.preferredRoom = action.payload;
    },

    EditPreferredRoomModal(state, action) {
      state.editPreferredRoomModal = action.payload;
    },

    PreferredRoomsSaved(state, action) {
      state.preferredRoomsSaved = action.payload;
    },

    AlgorithmStatus(state, action) {
      state.algorithmStatus = action.payload;
    },

    AlgorithmErrors(state, action) {
      state.algorithmErrors = action.payload;
    },

    ResultStudentData(state, action) {
      state.resultStudentData = action.payload;
    },

    UpdateSubjectIds(state, action) {
      state.updateSubjectIds = action.payload;
    },

    ClearErrorConfirmationModal(state, action) {
      state.clearErrorConfirmationModal = action.payload;
    },

    ErrorsListModal(state, action) {
      state.errorsListModal = action.payload;
    },

    ShowClearModal(state, action) {
      state.showClearModal = action.payload;
    },

    ClearType(state, action) {
      state.clearType = action.payload;
    },

    SearchStudentId(state, action) {
      state.searchStudentId = action.payload;
    },

    AccessLevelData(state, action) {
      state.accessLevelData = action.payload;
    },

    EditedSubjectRoomPreferrenceData(state, action) {
      state.editedSubjectRoomPreferrenceData = action.payload;
    },

    NewStudentId(state, action) {
      state.newStudentId = action.payload;
    },

    SelectedSeatingPreference(state, action) {
      state.selectedSeatingPreference = action.payload;
    },

    GenerateResultLoader(state, action) {
      state.generateResultLoader = action.payload;
    },

    DataImportErrorList(state, action) {
      state.dataImportErrorList = action.payload;
    },

    ExamDateAndTimeIdData(state, action) {
      state.examDateAndTimeIdData = action.payload;
    },

    ExamSubjectSummaryIdData(state, action) {
      state.examSubjectSummaryIdData = action.payload;
    },

    ResultRoomByExamDateAndTimeIdData(state, action) {
      state.resultRoomByExamDateAndTimeIdData = action.payload;
    },

    ResultSubjectByExamDateAndTimeIdData(state, action) {
      state.resultSubjectByExamDateAndTimeIdData = action.payload;
    },

    ResultStaffData(state, action) {
      state.resultStaffData = action.payload;
    },

    StaffDataImportErrorList(state, action) {
      state.staffDataImportErrorList = action.payload;
    },

    StaffSchedulingAlgorithmStatus(state, action) {
      state.staffSchedulingAlgorithmStatus = action.payload;
    },

    SelectedStaffSchedulePreference(state, action) {
      state.selectedStaffSchedulePreference = action.payload;
    },

    SeatingOrderData(state, action) {
      state.seatingOrderData = action.payload;
    },
  },
});

export default menu.reducer;

export const {
  BuildingData,
  RoomData,
  StudentData,
  StaffData,
  ExamDateAndTimeData,
  ExamDateData,
  SubjectData,
  InstitutionData,
  DataSummary,
  DefaultStartTime,
  DefaultEndTime,
  GanttGroup,
  GanttItem,
  LocalLoader,
  PreferredRoom,
  EditPreferredRoomModal,
  PreferredRoomsSaved,
  AlgorithmStatus,
  AlgorithmErrors,
  ResultStudentData,
  UpdateSubjectIds,
  ClearErrorConfirmationModal,
  ErrorsListModal,
  ShowClearModal,
  ClearType,
  SearchStudentId,
  AccessLevelData,
  EditedSubjectRoomPreferrenceData,
  NewStudentId,
  SelectedSeatingPreference,
  GenerateResultLoader,
  DataImportErrorList,
  ExamDateAndTimeIdData,
  ExamSubjectSummaryIdData,
  ResultRoomByExamDateAndTimeIdData,
  ResultSubjectByExamDateAndTimeIdData,
  ResultStaffData,
  StaffDataImportErrorList,
  StaffSchedulingAlgorithmStatus,
  SelectedStaffSchedulePreference,
  SeatingOrderData,
} = menu.actions;
